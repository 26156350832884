import api from '@apiInstance';

export default {
  add(data) {
    return api.post('/groups', data);
  },
  get(id) {
    return api.fetch('/groups/' + id);
  },
  list(params) {
    return api.fetch('/groups', { params });
  },
  update(data) {
    return api.patch('/groups', data);
  },
  delete(id) {
    return api.remove('/groups', id);
  },
};
